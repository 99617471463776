import { StatsigClient, StatsigUpdateDetails } from '@statsig/js-client';

let client = null;

/**
 * Initialize the Statsig client
 * @param {Object} user - The user object with a userID property
 * @returns {Promise<StatsigClient>} - The initialized Statsig client
 */
async function init(user = {}) {
	if (!client) {
		client = new StatsigClient(process.env.REACT_APP_STATSIG_SDK, user);
		await client.initializeAsync();
	}

	return client;
}

/**
 * Update the user for the Statsig client
 * @param {Object} user - The user object with a userID property
 * @returns {Promise<StatsigUpdateDetails>} - The updated Statsig client
 */
async function updateUser(user) {
	if (!client) {
		await init(user);
	}

	return await client.updateUserAsync(user);
}

/**
 * Check if a feature gate is enabled
 * @param {string} gateName - The name of the feature gate
 * @param {boolean} defaultValue - Default value if gate check fails
 * @returns {boolean} - Whether the gate is enabled
 */
function checkGate(gateName, defaultValue = false) {
	if (!client) {
		console.error('Statsig client not initialized');
		return defaultValue;
	}

	try {
		return client.checkGate(gateName);
	} catch (error) {
		console.error(`Error checking gate ${gateName}:`, error);
		return defaultValue;
	}
}

/**
 * Shutdown the Statsig client
 */
async function shutdown() {
	if (client) {
		await client.shutdown();
		client = null;
	}
}

export default {
	init,
	shutdown,
	checkGate,
	updateUser,
};
