import constants from '../../../contants';
import fetchWrapper from './../../../services/fetchWrapper';
import { store } from 'store';
import statsig from '@tools/statsig';

export const SET_WORKPLACES = 'WORKPLACES/SET_WORKPLACES';
export const WORKPLACE_SELECTED = 'WORKPLACES/WORKPLACE_SELECTED';
export const TOGGLE_LOADER = 'WORKPLACES/TOGGLE_LOADER';

/**
 * @function fetchWorkplaces
 * @description fetch workplaces from API
 */
export const fetchWorkplaces = async () => {
	const useWP2Endpoint = statsig.checkGate('wp2api');
	const baseUrl = useWP2Endpoint
		? `${constants.WP2_API_HOST}/inventory`
		: constants.API_ENDPOINT;
	const state = store.getState();
	const personId =
		constants.AUTHZERO_DEVELOPMENT_USER_ID ?? state?.auth?.user.personId;

	return fetchWrapper({
		endpoint: baseUrl + `/users/${personId}/workplaces`,
		method: 'GET',
	});
};

/**
 * @function setWorkplaces
 * @description set workplaces in state
 * @param {object} payload Object of workplaces
 */
export const setWorkplaces = (payload) => {
	return { type: SET_WORKPLACES, payload };
};

/**
 * @function selectWorkplace
 * @description set workplaces in state
 * @param {object} payload Object of workplaces
 */
export const selectWorkplace = (payload) => {
	return { type: WORKPLACE_SELECTED, payload };
};

/**
 * @function toggleLoader
 */
export const toggleLoader = (payload) => {
	return { type: TOGGLE_LOADER, payload };
};
